import type { MiddlewareHook } from "@/src/ui/router/route_middleware";
import { useAppSelector } from "@/src/ui/state";

export const useAuthMiddleware: MiddlewareHook = () => {
  const isLogged = useAppSelector((state) => state.auth.isLogged);

  return {
    redirectUrl: isLogged ? undefined : "/login",
  };
};
