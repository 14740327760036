import { css } from "styled-components";
import { includeMedia } from "./breakpoints";
import { spacing } from "./spacing";

export const px2rem = (target: string | number): string => {
  typeof target === "string" && (target = target.replace("px", ""));
  const fontSizeBase = 16;
  const remSize = Number(target) / fontSizeBase;
  return `${remSize}rem`;
};

export const layoutSize = css`
  width: 100%;
  padding-left: ${px2rem(spacing.size04)};
  padding-right: ${px2rem(spacing.size04)};

  ${includeMedia(
    "s",
    css`
      padding-left: ${px2rem(spacing.size24)};
      padding-right: ${px2rem(spacing.size24)};
    `
  )}

  ${includeMedia(
    "l",
    css`
      max-width: ${px2rem(1600)};
      padding-left: ${px2rem(spacing.size10)};
      padding-right: ${px2rem(spacing.size10)};
    `
  )}
`;
