import { Expose, Type } from "class-transformer";
import { Photo } from "./photo";

export enum CosmelanAdjuvant {
  CosmelanHomePack = "cosmelan_home_pack",
  Cosmelan2 = "cosmelan_2",
  MelanRecovery = "melan_recovery",
  Mesoprotech = "mesoprotech",
}

export enum OtherAdjuvant {
  SkinBalance = "skin_balance",
  AntiStressMask = "anti_stress_mask",
}

export enum PatientMood {
  Negative = "negative",
  Neutral = "neutral",
  Positive = "positive",
}

export class Session {
  @Expose()
  id!: number;
  @Expose()
  patient!: { id: number; full_name: string };
  @Expose()
  date!: string;
  @Expose({ name: "session_number" })
  sessionNumber!: number;
  @Expose({ name: "is_finished" })
  isFinished!: boolean;
  @Expose()
  evolution!: Record<string, string>;
  @Expose({ name: "expected_effects" })
  expectedEffects!: Record<string, string>;
  @Expose()
  adjuvants!: { cosmelan_adjuvants?: string[]; other_adjuvants?: string[] };
  @Expose({ name: "patient_evolution_state" })
  patientEvolutionState!: string;
  @Expose()
  @Type(() => Photo)
  photos!: Photo[];
}

export class SessionListItemData {
  @Expose()
  id!: number;
  @Expose()
  patient!: { id: number; full_name: string };
  @Expose()
  date!: string;
  @Expose({ name: "session_number" })
  sessionNumber!: number;
  @Expose({ name: "is_finished" })
  isFinished!: boolean;
}
