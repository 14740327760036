import type { MouseEventHandler, PropsWithChildren } from "react";
import type { CypressProps } from "@/src/ui/view_models/cypress";
import { ButtonStyledDanger, ButtonStyledFragment, ButtonStyledPrimary, ButtonStyledStandard } from "./button_link.styled";

export interface ButtonProps extends CypressProps {
  onClick?: MouseEventHandler;
  disabled?: boolean;
  bstyle?: "fragment" | "standard" | "primary" | "danger";
  to: string;
}

export const ButtonLink = ({ children, onClick, disabled, bstyle = "fragment", "data-cy": dataCy, to }: PropsWithChildren<ButtonProps>) => {
  switch (bstyle) {
    case "fragment":
      return (
        <ButtonStyledFragment to={to} onClick={onClick} disabled={disabled} data-cy={dataCy}>
          {children}
        </ButtonStyledFragment>
      );    
    
      case "standard":
        return (
          <ButtonStyledStandard  to={to} onClick={onClick} disabled={disabled} data-cy={dataCy}>
            {children}
          </ButtonStyledStandard>
        );    
      
      case "primary":
        return (
          <ButtonStyledPrimary  to={to} onClick={onClick} disabled={disabled} data-cy={dataCy}>
            {children}
          </ButtonStyledPrimary>
        );    
      
      case "danger":
      return (
        <ButtonStyledDanger to={to} onClick={onClick} disabled={disabled} data-cy={dataCy}>
          {children}
        </ButtonStyledDanger>
      );    
  }
};
