import Add from "./add.svg";
import AddOutline from "./add_outline.svg";
import ArrowLeft from "./arrow_left.svg";
import Calendar from "./calendar.svg";
import Check from "./check.svg";
import CheckOff from "./check_off.svg";
import CheckOn from "./check_on.svg";
import ChevronDown from "./chevron_down.svg";
import ChevronLeft from "./chevron_left.svg";
import ChevronRight from "./chevron_right.svg";
import ChevronUp from "./chevron_up.svg";
import Close from "./close.svg";
import Decoration1 from "./decoration1.svg";
import Delete from "./delete.svg";
import Down from "./down.svg";
import Edit from "./edit.svg";
import Error from "./error.svg";
import Files from "./files.svg";
import Home from "./home.svg";
import Info from "./info.svg";
import Loader from "./loader.svg";
import LoginLogo from "./login_logo.svg";
import Logo from "./logo.svg";
import Logo2 from "./logo2.svg";
import Menu from "./menu.svg";
import MoodNegative from "./mood_negative.svg";
import MoodNeutral from "./mood_neutral.svg";
import MoodPositive from "./mood_positive.svg";
import Options from "./options.svg";
import Search from "./search.svg";
import Up from "./up.svg";
import User from "./user.svg";
import UserMenu from "./user_menu.svg";
import Warning from "./warning.svg";
import Activity from "./activity.svg";
import Users from "./users.svg";
import Resources from "./resources.svg";
import HomeLogo from "./home_logo.svg";

export {
  Add,
  AddOutline,
  ArrowLeft,
  Calendar,
  Check,
  CheckOff,
  CheckOn,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Close,
  Decoration1,
  Delete,
  Down,
  Edit,
  Error,
  Files,
  Home,
  Info,
  Loader,
  LoginLogo,
  Logo,
  Logo2,
  Menu,
  MoodNegative,
  MoodNeutral,
  MoodPositive,
  Options,
  Search,
  Up,
  User,
  UserMenu,
  Warning,
  Activity,
  Users,
  Resources,
  HomeLogo,
};
