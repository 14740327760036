import type { Middleware } from "@reduxjs/toolkit";
import { isRejected } from "@reduxjs/toolkit";
import type { AnyAction } from "redux";

export const errorMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    if (isRejected(action) && !action.meta.aborted) {
      console.error(
        new Error((action.error.message || "UNKNOWN_ERROR").toString())
      );
    }

    return next(action);
  };
