import { combineReducers, configureStore } from "@reduxjs/toolkit";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import ui from "./ui.slice";
import misc from "./misc.slice";
import auth from "@/src/ui/state/auth.slice";
import patients from "@/src/ui/pages/patients/state/patients.slice";
import sessions from "@/src/ui/pages/sessions/state/sessions.slice";
import { loadState } from "./persistent_state";
import { errorMiddleware } from "./middlewares/error_middleware";
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/es/persistReducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['patients','sessions'],
}

const rootReducer = combineReducers({ 
  ui: ui,
  misc: misc,
  auth: auth,
  patients:patients,
  sessions:sessions
})


const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(errorMiddleware),
  preloadedState: loadState(),
});



export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
