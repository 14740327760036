import styled, { css } from "styled-components";
import { layoutSize, px2rem } from "@/src/ui/styles/utils";
import { spacing } from "../../styles/spacing";
import { includeMedia } from "@/src/ui/styles/breakpoints";
import { colorSpecific } from "../../styles/colors_specific";
import { typography } from "../../styles/typography";

type MainAttrs = { fullWidth: boolean };
type NavAttrs = { transparent: boolean };

const sideMenuWidth = "240px";

export const BaseLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${includeMedia(
    "l",
    css`
      display: flex;
      flex-direction: row;
    `
  )}
`;

export const BaseLayoutSideMenuWrapperStyled = styled.div`
  display: none;

  ${includeMedia(
    "l",
    css`
      display: block;
      width: ${sideMenuWidth};
      flex: none;
    `
  )}
`;

export const BaseLayoutMobileNavStyled = styled.header.attrs<
  NavAttrs,
  NavAttrs
>((props) => ({
  transparent: props.transparent,
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${px2rem(spacing.size02)};
  padding-left: ${px2rem(spacing.size04)};
  background-color: ${(props) =>
    props.transparent ? "transparent" : colorSpecific.backgroundInverse};
  color: white;
  position: relative;

  ${includeMedia(
    "l",
    css`
      display: none;
    `
  )}
`;

export const BaseLayoutMobileLogo = styled.div`
  cursor: pointer;
`;

export const BaseLayoutMainStyled = styled.div.attrs<MainAttrs, MainAttrs>(
  (props) => ({
    fullWidth: props.fullWidth,
  })
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  ${includeMedia(
    "l",
    css`
      width: calc(100% - ${sideMenuWidth});
    `
  )}

  main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    overflow-y: ${(props) => (props.fullWidth ? "visible" : "auto")};

    ${includeMedia(
      "l",
      css`
        position: relative;
      `
    )}

    ${layoutSize};

    ${(props) =>
      props.fullWidth &&
      css`
        padding-left: 0px !important;
        padding-right: 0px !important;
      `}
  }
`;

export const BaseLayoutNavStyled = styled.header.attrs<NavAttrs, NavAttrs>(
  (props) => ({
    transparent: props.transparent,
  })
)`
  display: none;
  flex: 0 0 auto;
  align-items: center;
  justify-content: end;
  padding-top: ${px2rem(spacing.size03)};
  padding-bottom: ${px2rem(spacing.size03)};
  gap: ${px2rem(spacing.size04)};
  position: relative;
  background-color: ${(props) => (props.transparent ? "transparent" : "unset")};

  ${layoutSize};

  ${includeMedia(
    "l",
    css`
      display: flex;
    `
  )}

  .user {
    display: flex;
    align-items: center;

    .avatar {
      background-color: ${colorSpecific.background2};
      height: ${px2rem(spacing.size10)};
      width: ${px2rem(spacing.size10)};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        ${typography.headingS};
      }
    }

    .username {
      ${typography.bodyM};
      margin-left: ${px2rem(spacing.size02)};
      color: ${(props) =>
        props.transparent
          ? colorSpecific.contentInverse
          : colorSpecific.content1};
    }

    .dropdown-icon {
      padding: 0px ${px2rem(spacing.size02)};
      color: ${(props) =>
        props.transparent
          ? colorSpecific.contentInverse
          : colorSpecific.content1};
    }
  }

  ul {
    display: flex;
    align-items: center;

    li {
      margin-left: ${px2rem(8)};
      list-style: none;
    }
  }
`;
