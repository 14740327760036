export enum NewPatientStep5PregnancyAnswer {
  A = "a",
  B = "b",
}

export enum NewPatientStep5DiseasesAnswer {
  A = "a",
  B = "b",
  C = "c",
  D = "d",
  E = "e",
  F = "f",
  G = "g",
  H = "h",
}

export enum NewPatientStep5MedicationsAnswer {
  A = "a",
  B = "b",
  C = "c",
  D = "d",
  E = "e",
}

export enum NewPatientStep5AllergyAnswer {
  A = "a",
  B = "b",
}

export enum NewPatientStep5DisturbancesAnswer {
  A = "a",
  B = "b",
  C = "c",
  D = "d",
  E = "e",
  F = "f",
}

export enum NewPatientStep5RecentTreatmentAnswer {
  A = "a",
  B = "b",
}

export enum NewPatientStep5SensitivityAnswer {
  A = "a",
  B = "b",
}
