import type { IEnvVars } from "../interfaces/env_vars";
import { injectable } from "inversify";

@injectable()
export class EnvVars implements IEnvVars {
  serviceUrl = `${process.env.REACT_APP_API_URL}${
    this.isDevelopment ? "/rest" : ""
  }`;

  get isProduction() {
    return process.env.NODE_ENV === "production";
  }

  get isDevelopment() {
    return process.env.NODE_ENV === "development";
  }

  sentryDSN?: string =
    process.env.SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN;

  sentryEnabled: boolean = process.env.REACT_APP_SENTRY_ENABLED === "true";
}
