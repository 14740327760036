import type { FlattenSimpleInterpolation } from "styled-components";
import { css } from "styled-components";
import { px2rem } from "./utils";

export interface Typography {
  bodyS: FlattenSimpleInterpolation;
  bodyM: FlattenSimpleInterpolation;
  bodyL: FlattenSimpleInterpolation;
  bodyXL: FlattenSimpleInterpolation;
  heading2XS: FlattenSimpleInterpolation;
  headingXS: FlattenSimpleInterpolation;
  headingS: FlattenSimpleInterpolation;
  headingM: FlattenSimpleInterpolation;
  headingL: FlattenSimpleInterpolation;
  headingXL: FlattenSimpleInterpolation;
  heading2XL: FlattenSimpleInterpolation;
  button: FlattenSimpleInterpolation;
}

// Body
const bodyS = css`
  font-size: ${px2rem(12)};
  line-height: ${px2rem(16)};
  font-weight: 400;
`;
const bodyM = css`
  font-size: ${px2rem(14)};
  line-height: ${px2rem(20)};
  font-weight: 400;
`;
const bodyL = css`
  font-size: ${px2rem(16)};
  line-height: ${px2rem(22)};
  font-weight: 400;
`;
const bodyXL = css`
  font-size: ${px2rem(32)};
  line-height: ${px2rem(40)};
  font-weight: 400;
`;

// Heading
const heading2XS = css`
  font-size: ${px2rem(14)};
  line-height: ${px2rem(20)};
  font-weight: 500;
  text-transform: lowercase;
`;
const headingXS = css`
  font-size: ${px2rem(16)};
  line-height: ${px2rem(22)};
  font-weight: 500;
  text-transform: lowercase;
`;
const headingS = css`
  font-size: ${px2rem(20)};
  line-height: ${px2rem(26)};
  font-weight: 500;
  text-transform: lowercase;
`;
const headingM = css`
  font-size: ${px2rem(24)};
  line-height: ${px2rem(32)};
  font-weight: 500;
  text-transform: lowercase;
`;
const headingL = css`
  font-size: ${px2rem(32)};
  line-height: ${px2rem(40)};
  font-weight: 500;
  text-transform: lowercase;
`;
const headingXL = css`
  font-size: ${px2rem(40)};
  line-height: ${px2rem(48)};
  font-weight: 500;
  text-transform: lowercase;
`;
const heading2XL = css`
  font-size: ${px2rem(56)};
  line-height: ${px2rem(64)};
  font-weight: 500;
  text-transform: lowercase;
`;

// Miscellaneous
const button = css`
  font-size: ${px2rem(14)};
  line-height: ${px2rem(20)};
  font-weight: 500;
`;

export const typography: Typography = {
  bodyS,
  bodyM,
  bodyL,
  bodyXL,
  heading2XS,
  headingXS,
  headingS,
  headingM,
  headingL,
  headingXL,
  heading2XL,
  button,
};
