import { LocalStorageKey } from "../view_models/local_storage";

export const loadState = () => {
  const accessToken = localStorage.getItem(LocalStorageKey.AccessToken);
  const user = localStorage.getItem(LocalStorageKey.User);

  return {
    auth: { isLogged: !!accessToken, user: user && JSON.parse(user) },
  };
};
