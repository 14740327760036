import { useTranslation } from "react-i18next";
import { Add, Close, Files, Home, UserMenu } from "../../assets/icons";
import { ActionButton } from "../buttons/action_button/action_button";
import { ButtonLink } from "../buttons/button_link/button_link";
import {
  MobileMenuHeaderStyled,
  MobileMenuNavigationStyled,
  MobileMenuNewStyled,
  MobileMenuStyled,
} from "./mobile_menu.styled";

interface MobileMenuProps {
  onClose: () => void;
}

const MobileMenu = (props: MobileMenuProps) => {
  const { t } = useTranslation("commons", { keyPrefix: "sidebar" });

  return (
    <MobileMenuStyled>
      <MobileMenuHeaderStyled>
        <ActionButton onClick={props.onClose}>
          <Close />
        </ActionButton>
      </MobileMenuHeaderStyled>

      <MobileMenuNewStyled>
        <ButtonLink
          onClick={props.onClose}
          to={"/patients/new"}
          bstyle="standard"
        >
          <Add />
          {t("new_patient")}
        </ButtonLink>
      </MobileMenuNewStyled>
      <MobileMenuNavigationStyled>
        <ButtonLink onClick={props.onClose} to="/app">
          <Home />
          {t("home")}
        </ButtonLink>
        <ButtonLink onClick={props.onClose} to="/patients">
          <UserMenu />
          {t("patients")}
        </ButtonLink>
        <ButtonLink onClick={props.onClose} to="/resources">
          <Files />
          {t("resources")}
        </ButtonLink>
      </MobileMenuNavigationStyled>
    </MobileMenuStyled>
  );
};

export default MobileMenu;
