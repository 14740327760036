import { Expose } from "class-transformer";

export enum PhotoType {
  Front = "front",
  Left = "left",
  Right = "right",
}

export enum PhotoSize {
  Original = "original",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export class Photo {
  @Expose()
  id!: number;
  @Expose()
  session!: number;
  @Expose({ name: "photo_type" })
  photoType!: PhotoType;
  @Expose({ name: "photo_size" })
  photoSize!: PhotoSize;
  @Expose()
  photo!: string;
}
