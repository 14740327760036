import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import { TYPES } from "@/src/core/app/ioc/types";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import type { UploadPhotoUseCase } from "@/src/core/sessions/domain/use_cases/upload_photo_use_case";
import type { RootState } from "@/src/ui/state";
import type { SessionsSliceState } from "../view_models/sessions.slice";
import type { GetSessionByIdUseCase } from "@/src/core/sessions/domain/use_cases/get_session_by_id_use_case";
import type {
  Session,
  SessionListItemData,
} from "@/src/core/sessions/domain/models/session";
import { PatientMood } from "@/src/core/sessions/domain/models/session";
import type { UpdateSessionData } from "@/src/core/sessions/data/interfaces/update_session_data";
import type { UpdateSessionUseCase } from "@/src/core/sessions/domain/use_cases/update_session_use_case";
import { setLoader } from "@/src/ui/state/ui.slice";
import type { CreateSessionUseCase } from "@/src/core/sessions/domain/use_cases/create_session_use_case";
import type { CreateSessionData } from "@/src/core/sessions/data/interfaces/create_session_data";
import type { DeletePhotoUseCase } from "@/src/core/sessions/domain/use_cases/delete_photo_use_case";
import type { Photo } from "@/src/core/sessions/domain/models/photo";
import type { GetSessionsUseCase } from "@/src/core/sessions/domain/use_cases/get_sessions_use_case";
import type { GetSessionsPayload } from "@/src/core/sessions/data/interfaces/get_sessions_payload";
import type { GetSessionsResponse } from "@/src/core/sessions/data/interfaces/get_sessions_response";
import { getPatientThunk } from "@/src/ui/pages/patients/state/patients.slice";

const initialStateFactory = (): SessionsSliceState => ({
  sessionForm: {
    evolution: {
      frontPhoto: null,
      leftPhoto: null,
      rightPhoto: null,
      hyperpigmentationIntensity: 1,
      texture: 1,
      brightness: 1,
      colorUniformity: 1,
      wrinkles: 1,
      extraInfo: "",
    },
    expectedEffects: {
      erythema: "",
      peeling: "",
      itching: "",
      irritation: "",
      swelling: "",
      others_name: "",
      others: "",
    },
    adjuvants: {
      cosmelanAdjuvants: [],
      otherAdjuvants: [],
    },
    mood: undefined,
    isFinished: false,
  },
  currentSession: {
    id: 0,
    patient: { id: 0, full_name: "" },
    date: "",
    sessionNumber: 0,
    isFinished: false,
    evolution: { "": "" },
    expectedEffects: { "": "" },
    adjuvants: {},
    patientEvolutionState: PatientMood.Neutral,
    photos: [],
  },
});

export const createSessionThunk = createAsyncThunk<
  Session,
  CreateSessionData,
  { state: RootState }
>("sessions.slice/createSession", async (payload, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const useCase = await locator.get<IocProvider<CreateSessionUseCase>>(
      TYPES.CreateSessionUseCase
    )();
    const session = await useCase.execute(payload);
    await getSessionByIdThunk(payload.patient);
    return session;
  } finally {
    dispatch(setLoader(false));
  }
});

export const getSessionByIdThunk = createAsyncThunk<
  Session,
  number,
  { state: RootState }
>("sessions.slice/getSessionById", async (sessionId, { dispatch }) => {
  try {
    dispatch(setLoader(true));

    const useCase = await locator.get<IocProvider<GetSessionByIdUseCase>>(
      TYPES.GetSessionByIdUseCase
    )();
    const session = await useCase.execute(sessionId);

    return session;
  } finally {
    dispatch(setLoader(false));
  }
});

export const updateSessionThunk = createAsyncThunk<
  void,
  { id: number; data: UpdateSessionData },
  { state: RootState }
>("sessions.slice/updateSession", async (payload, { dispatch }) => {
  try {
    dispatch(setLoader(true));

    const useCase = await locator.get<IocProvider<UpdateSessionUseCase>>(
      TYPES.UpdateSessionUseCase
    )();

    const response = await useCase.execute(payload.id, payload.data);
    await getPatientThunk({ id: response.patient.id });
  } finally {
    dispatch(setLoader(false));
  }
});

export const uploadSessionPhotoThunk = createAsyncThunk<
  Photo,
  { sessionId: number; photoType: string; photo: File },
  { state: RootState }
>("sessions.slice/uploadSessionPhoto", async (payload) => {
  const useCase = await locator.get<IocProvider<UploadPhotoUseCase>>(
    TYPES.UploadPhotoUseCase
  )();

  return useCase.execute(payload.sessionId, {
    session: payload.sessionId,
    photo_type: payload.photoType,
    photo: payload.photo,
  });
});

export const deletePhotoThunk = createAsyncThunk<
  void,
  number,
  { state: RootState }
>("sessions.slice/deletePhoto", async (id) => {
  const useCase = await locator.get<IocProvider<DeletePhotoUseCase>>(
    TYPES.DeletePhotoUseCase
  )();

  await useCase.execute(id);
});

export const getSessionsThunk = createAsyncThunk<
  SessionListItemData[],
  GetSessionsPayload,
  { state: RootState }
>("sessions.slice/getSessions", async (payload, { dispatch }) => {
  try {
    dispatch(setLoader(true));

    const useCase = await locator.get<IocProvider<GetSessionsUseCase>>(
      TYPES.GetSessionsUseCase
    )();
    const results: SessionListItemData[] = [];
    let nextUrl: string | null = null;

    do {
      const response: Omit<GetSessionsResponse, "results"> & {
        results: SessionListItemData[];
      } = await useCase.execute({ ...payload, url: nextUrl });

      nextUrl = response.next;
      results.push(...response.results);
    } while (nextUrl);

    return results;
  } finally {
    dispatch(setLoader(false));
  }
});

const sessionsSlice = createSlice({
  name: "sessions.slice",
  initialState: initialStateFactory(),
  reducers: {
    resetCurrentSessionForm: (state) => {
      Object.assign(state, initialStateFactory());
    },
    updateSessionForm: (
      state,
      action: PayloadAction<Partial<SessionsSliceState["sessionForm"]>>
    ) => {
      Object.assign(state.sessionForm, action.payload);
    },
    setFrontPhoto(state, action: PayloadAction<Photo | null>) {
      state.sessionForm.evolution.frontPhoto = action.payload;
    },
    setLeftPhoto(state, action: PayloadAction<Photo | null>) {
      state.sessionForm.evolution.leftPhoto = action.payload;
    },
    setRightPhoto(state, action: PayloadAction<Photo | null>) {
      state.sessionForm.evolution.rightPhoto = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSessionByIdThunk.fulfilled, (state, { payload }) => {
      state.currentSession = payload
        ? payload
        : initialStateFactory().currentSession;
    });
  },
});

function selectSessionsBase(state: RootState) {
  return state.sessions;
}

export const getCurrentSession = createSelector(
  [selectSessionsBase],
  (slice) => slice.currentSession
);

export const sessionsActions = sessionsSlice.actions;
export default sessionsSlice.reducer;
