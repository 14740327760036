import styled from "styled-components";
import { colorSpecific } from "../../styles/colors_specific";
import { spacing } from "../../styles/spacing";
import { px2rem } from "../../styles/utils";

export const MobileMenuStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background-color: ${colorSpecific.backgroundInverse};
  color: white;
  z-index: ${spacing.size01};
`;

export const MobileMenuHeaderStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${px2rem(spacing.size02)};
  padding-left: ${px2rem(spacing.size04)};
  background-color: ${colorSpecific.backgroundInverse};
  color: white;
`;

export const MobileMenuNewStyled = styled.div`
  display: flex;
  padding: ${px2rem(spacing.size06)};
`;

export const MobileMenuNavigationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${px2rem(spacing.size04)};
  padding: ${px2rem(spacing.size03)};
`;
