import { createGlobalStyle } from "styled-components";
import { ResetCSS } from "@/src/ui/styles/reset";
import { colors } from "./colors";
import { fonts, FontStyled } from "./fonts";
import { typography } from "@/src/ui/styles/typography";
import { px2rem } from "@/src/ui/styles/utils";
import { spacing } from "./spacing";
import { colorSpecific } from "./colors_specific";

const Styles = createGlobalStyle`
  * {
    &:focus {
      outline: ${px2rem(2)} solid ${colors.gray40};
    }
  }
  html {
    height: 100%;
  }
  body {
    background-color: ${colorSpecific.background1};
    height: 100%;
    overflow: hidden;
  }
  #root {
    height: 100%;
  }
  a {
    color: ${colorSpecific.content1};
  }


  /* Typography */
  * {
    ${fonts.Graphik};
  }
  h1 {
    ${typography.headingL}
  }
  h2 {
    ${typography.headingM}
  }
  h3 {
    ${typography.headingS}
  }
  h4 {
    ${typography.headingXS}
  }
  h5 {
    ${typography.heading2XS}
  }
  p {
    ${typography.bodyM}
  }
  small {
    ${typography.bodyS}
  }

  /* Forms */
  form {
    input,textarea {
      width: 100%;
    }
    input[type=radio] {
      width: auto;
    }
  }
  label {
    ${typography.bodyS};
    margin-bottom: ${px2rem(spacing.size01)};
    display: block;
  }
  .error {
    ${typography.bodyS};
    color: ${colorSpecific.semanticDanger}
  }
  .form-item + .form-item {
    margin-top: ${px2rem(spacing.size06)};
  }

  /* Animations */
  @-webkit-keyframes infinite-rotation /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes infinite-rotation {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .infinite-rotation {
    -webkit-animation: infinite-rotation 2s linear infinite;
    -moz-animation: infinite-rotation 2s linear infinite;
    -ms-animation: infinite-rotation 2s linear infinite;
    -o-animation: infinite-rotation 2s linear infinite;
    animation: infinite-rotation 2s linear infinite;
  }
`;

export const GlobalStyles = () => (
  <>
    <Styles />
    <ResetCSS />
    <FontStyled />
  </>
);
