import { Menu } from "@headlessui/react";
import type { DropdownMenuItem } from "../../view_models/ui";
import Styled from "./dropdown_menu.styled";

interface DropdownMenuProps {
  button: JSX.Element;
  items: DropdownMenuItem[];
}

const DropdownMenu = (props: DropdownMenuProps) => {
  return (
    <Styled.Wrapper>
      <Menu as="div">
        <Styled.MenuButton>{props.button}</Styled.MenuButton>
        <Styled.MenuItems>
          {props.items.map((item) => (
            <Styled.MenuItem as="div" key={item.key} onClick={item.onClick}>
              {item.separator && <Styled.MenuItemSeparator />}
              <Styled.MenuItemTitle>{item.title}</Styled.MenuItemTitle>
            </Styled.MenuItem>
          ))}
        </Styled.MenuItems>
      </Menu>
    </Styled.Wrapper>
  );
};

export default DropdownMenu;
