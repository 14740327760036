export const TYPES = {
  //Services
  IEnvVars: Symbol("IEnvVars"),
  RestService: Symbol("RestService"),
  PatientService: Symbol("PatientService"),

  //Repositories
  IAuthRepository: Symbol("IAuthRespository"),
  IPatientsRepository: Symbol("IPatientsRepository"),
  ISessionsRepository: Symbol("ISessionsRepository"),

  //Use Cases
  LoginUseCase: Symbol("LoginUseCase"),
  MeUseCase: Symbol("MeUseCase"),
  CreatePatientUseCase: Symbol("CreatePatientUseCase"),
  UpdatePatientUseCase: Symbol("UpdatePatientUseCase"),
  GetSessionByIdUseCase: Symbol("GetSessionByIdUseCase"),
  GetSessionsUseCase: Symbol("GetSessionsUseCase"),
  UploadPhotoUseCase: Symbol("UploadPhotoUseCase"),
  DeletePhotoUseCase: Symbol("DeletePhotoUseCase"),
  UpdateSessionUseCase: Symbol("UpdateSessionUseCase"),
  CreateSessionUseCase: Symbol("CreateSessionUseCase"),
  DeletePatientUseCase: Symbol("DeletePatientUseCase"),
  GetPatientListUseCase: Symbol("GetPatientListUseCase"),
  GetPatientUseCase: Symbol("GetPatientUseCase"),
};
