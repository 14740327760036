import "reflect-metadata";
import { Container } from "inversify";
import type { IEnvVars } from "@/src/core/app/domain/interfaces/env_vars";
import { TYPES } from "./types";
import { EnvVars } from "@/src/core/app/domain/models/env_vars";
import type { IRestDataSource } from "@/src/common/interfaces/rest_data_source";
import { bindDynamicModule } from "./utils";
import type { IocProvider } from "./interfaces";
import type { RestService } from "../data/services/rest_service";
import type { IPatientsRepository } from "../../patients/domain/interfaces/patients_repository";
import type { ISessionsRepository } from "../../sessions/domain/interfaces/sessions_repository";
import type { CreatePatientUseCase } from "../../patients/domain/use_cases/create_patient_use_case";
import type { LoginUseCase } from "../../auth/domain/use_cases/login_use_case";
import type { MeUseCase } from "../../auth/domain/use_cases/me_use_case";
import type { IAuthRepository } from "../../auth/domain/interfaces/auth_repository";
import type { UpdatePatientUseCase } from "../../patients/domain/use_cases/update_patient_use_case";
import type { UploadPhotoUseCase } from "../../sessions/domain/use_cases/upload_photo_use_case";
import type { DeletePhotoUseCase } from "../../sessions/domain/use_cases/delete_photo_use_case";
import type { GetSessionByIdUseCase } from "../../sessions/domain/use_cases/get_session_by_id_use_case";
import type { UpdateSessionUseCase } from "../../sessions/domain/use_cases/update_session_use_case";
import type { CreateSessionUseCase } from "../../sessions/domain/use_cases/create_session_use_case";
import type { GetPatientListUseCase } from "../../patients/domain/use_cases/get_patient_list_use_case";
import type { GetPatientUseCase } from "../../patients/domain/use_cases/get_patient_use_case";
import type { DeletePatientUseCase } from "../../patients/domain/use_cases/delete_patient_use_case";
import type { PatientService } from "../../patients/domain/services/patient_service";
import type { GetSessionsUseCase } from "../../sessions/domain/use_cases/get_sessions_use_case";

const locator = new Container();
locator.bind<IEnvVars>(TYPES.IEnvVars).to(EnvVars);

bindDynamicModule<IocProvider<IRestDataSource>, RestService>(
  TYPES.RestService,
  () =>
    import("../data/services/rest_service").then((module) => module.RestService)
);
bindDynamicModule<IocProvider<PatientService>, PatientService>(
  TYPES.PatientService,
  () =>
    import("../../patients/domain/services/patient_service").then(
      (module) => module.PatientService
    )
);
// Repositories
bindDynamicModule<IocProvider<IAuthRepository>, IAuthRepository>(
  TYPES.IAuthRepository,
  () =>
    import("../../auth/data/repositories/auth_repository").then(
      (module) => module.AuthRepository
    )
);
bindDynamicModule<IocProvider<IPatientsRepository>, IPatientsRepository>(
  TYPES.IPatientsRepository,
  () =>
    import("../../patients/data/repositories/patients_repository").then(
      (module) => module.PatientsRepository
    )
);
bindDynamicModule<IocProvider<ISessionsRepository>, ISessionsRepository>(
  TYPES.ISessionsRepository,
  () =>
    import("../../sessions/data/repositories/sessions_repository").then(
      (module) => module.SessionsRepository
    )
);

// Use cases
bindDynamicModule<IocProvider<LoginUseCase>, LoginUseCase>(
  TYPES.LoginUseCase,
  () =>
    import("../../auth/domain/use_cases/login_use_case").then(
      (module) => module.LoginUseCase
    )
);
bindDynamicModule<IocProvider<MeUseCase>, MeUseCase>(TYPES.MeUseCase, () =>
  import("../../auth/domain/use_cases/me_use_case").then(
    (module) => module.MeUseCase
  )
);
bindDynamicModule<IocProvider<CreatePatientUseCase>, CreatePatientUseCase>(
  TYPES.CreatePatientUseCase,
  () =>
    import("../../patients/domain/use_cases/create_patient_use_case").then(
      (module) => module.CreatePatientUseCase
    )
);
bindDynamicModule<IocProvider<UpdatePatientUseCase>, UpdatePatientUseCase>(
  TYPES.UpdatePatientUseCase,
  () =>
    import("../../patients/domain/use_cases/update_patient_use_case").then(
      (module) => module.UpdatePatientUseCase
    )
);
bindDynamicModule<IocProvider<GetSessionByIdUseCase>, GetSessionByIdUseCase>(
  TYPES.GetSessionByIdUseCase,
  () =>
    import("../../sessions/domain/use_cases/get_session_by_id_use_case").then(
      (module) => module.GetSessionByIdUseCase
    )
);
bindDynamicModule<IocProvider<GetSessionsUseCase>, GetSessionsUseCase>(
  TYPES.GetSessionsUseCase,
  () =>
    import("../../sessions/domain/use_cases/get_sessions_use_case").then(
      (module) => module.GetSessionsUseCase
    )
);
bindDynamicModule<IocProvider<UploadPhotoUseCase>, UploadPhotoUseCase>(
  TYPES.UploadPhotoUseCase,
  () =>
    import("../../sessions/domain/use_cases/upload_photo_use_case").then(
      (module) => module.UploadPhotoUseCase
    )
);
bindDynamicModule<IocProvider<DeletePhotoUseCase>, DeletePhotoUseCase>(
  TYPES.DeletePhotoUseCase,
  () =>
    import("../../sessions/domain/use_cases/delete_photo_use_case").then(
      (module) => module.DeletePhotoUseCase
    )
);
bindDynamicModule<IocProvider<UpdateSessionUseCase>, UpdateSessionUseCase>(
  TYPES.UpdateSessionUseCase,
  () =>
    import("../../sessions/domain/use_cases/update_session_use_case").then(
      (module) => module.UpdateSessionUseCase
    )
);
bindDynamicModule<IocProvider<CreateSessionUseCase>, CreateSessionUseCase>(
  TYPES.CreateSessionUseCase,
  () =>
    import("../../sessions/domain/use_cases/create_session_use_case").then(
      (module) => module.CreateSessionUseCase
    )
);
bindDynamicModule<IocProvider<DeletePatientUseCase>, DeletePatientUseCase>(
  TYPES.DeletePatientUseCase,
  () =>
    import("../../patients/domain/use_cases/delete_patient_use_case").then(
      (module) => module.DeletePatientUseCase
    )
);
bindDynamicModule<IocProvider<GetPatientListUseCase>, GetPatientListUseCase>(
  TYPES.GetPatientListUseCase,
  () =>
    import("../../patients/domain/use_cases/get_patient_list_use_case").then(
      (module) => module.GetPatientListUseCase
    )
);
bindDynamicModule<IocProvider<GetPatientUseCase>, GetPatientUseCase>(
  TYPES.GetPatientUseCase,
  () =>
    import("../../patients/domain/use_cases/get_patient_use_case").then(
      (module) => module.GetPatientUseCase
    )
);

export { locator };
