export enum Language {
  Spanish = "es",
  English = "en",
  Portuguese = "pt",
  Polish = "pl",
  Dutch = "nl",
  French = "fr",
}

export interface ConfigFormData {
  language: string;
}
