import { history } from "@/src/index";
import { useTranslation } from "react-i18next";
import { Add, Files, Home, Logo2, UserMenu } from "../../assets/icons";
import { ButtonLink } from "../buttons/button_link/button_link";
import {
  SideMenuLogoStyled,
  SideMenuNavigationStyled,
  SideMenuNewStyled,
  SideMenuStyled,
} from "./side_menu.styled";

const SideMenu = () => {
  const { t } = useTranslation("commons", { keyPrefix: "sidebar" });
  const onLogoClicked = () => {
    history.push("/app");
  };

  return (
    <SideMenuStyled>
      <SideMenuLogoStyled onClick={onLogoClicked}>
        <Logo2 />
      </SideMenuLogoStyled>
      <SideMenuNewStyled>
        <ButtonLink to={"/patients/new"} bstyle="standard">
          <Add />
          {t("new_patient")}
        </ButtonLink>
      </SideMenuNewStyled>
      <SideMenuNavigationStyled>
        <ButtonLink to="/app">
          <Home />
          {t("home")}
        </ButtonLink>
        <ButtonLink to="/patients">
          <UserMenu />
          {t("patients")}
        </ButtonLink>
        <ButtonLink to="/resources">
          <Files />
          {t("resources")}
        </ButtonLink>
      </SideMenuNavigationStyled>
    </SideMenuStyled>
  );
};

export default SideMenu;
