import styled, { css } from "styled-components";
import type { ButtonProps } from "@/src/ui/components/buttons/button/button";
import { px2rem } from "@/src/ui/styles/utils";
import { spacing } from "@/src/ui/styles/spacing";
import { colorSpecific } from "@/src/ui/styles/colors_specific";
import { typography } from "@/src/ui/styles/typography";

export const ActionButtonStyled = styled.button<ButtonProps>`
  height: ${px2rem(spacing.size11)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2rem(spacing.size03)} ${px2rem(spacing.size04)};
  ${typography.button};
  ${(props) =>
    props.bstyle === "standard"
      ? css`
          color: ${colorSpecific.content3};
        `
      : props.bstyle === "primary"
      ? css`
          color: ${colorSpecific.semanticHighlight};
        `
      : props.bstyle === "danger"
      ? css`
          color: ${colorSpecific.semanticDanger};
        `
      : css`
          color: ${colorSpecific.content3};
        `}

  &:hover,&:focus {
    ${(props) =>
      props.bstyle === "standard"
        ? css`
            color: ${colorSpecific.contentHover};
          `
        : props.bstyle === "primary"
        ? css`
            color: ${colorSpecific.semanticHighlightHover};
          `
        : props.bstyle === "danger"
        ? css`
            color: ${colorSpecific.semanticDangerHover};
          `
        : css`
            color: ${colorSpecific.contentHover};
          `}
    background-color: ${(props) =>
      props.disabled ? "unset" : colorSpecific.backgroundHover};
  }
`;
