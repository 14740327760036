export const spacing = {
  size01: 4,
  size02: 8,
  size03: 12,
  size04: 16,
  size05: 20,
  size06: 24,
  size07: 28,
  size08: 32,
  size09: 36,
  size10: 40,
  size11: 44,
  size12: 48,
  size13: 52,
  size14: 56,
  size15: 60,
  size16: 64,
  size17: 68,
  size18: 72,
  size19: 80,
  size20: 84,
  size21: 88,
  size22: 92,
  size23: 96,
  size24: 100,
  size25: 104,
  size26: 108,
  size27: 112,
  size28: 116,
  size29: 120,
  size30: 124,
  size31: 128,
  size32: 132,
  size33: 136,
  size34: 140,
  size35: 144,
  size36: 148,
  size37: 152,
  size38: 156,
  size39: 160,
  size40: 164,
  size41: 168,
  size42: 172,
  size43: 180,
  size44: 184,
  size45: 188,
  size46: 192,
  size47: 196,
  size48: 200,
};
