import styled from "styled-components";
import { colors } from "../../../styles/colors";
import type { ButtonProps } from "@/src/ui/components/buttons/button/button";
import { px2rem } from "@/src/ui/styles/utils";
import { spacing } from "@/src/ui/styles/spacing";
import { colorSpecific } from "@/src/ui/styles/colors_specific";
import { typography } from "@/src/ui/styles/typography";
import { NavLink } from "react-router-dom";

export const ButtonStyledFragment = styled(NavLink)<ButtonProps>`
  height: ${px2rem(spacing.size11)};
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${px2rem(spacing.size02)};
  justify-content: start;
  align-items: center;
  padding: ${px2rem(spacing.size03)} ${px2rem(spacing.size04)};
  cursor: pointer;
  background-color: ${colorSpecific.backgroundInverse};
  color: ${colorSpecific.contentInverse};
  ${typography.button};
  opacity: 60%;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 80%;
  }

  &.active {
    opacity: 100%;
  }
`;

export const ButtonStyledStandard = styled(NavLink)<ButtonProps>`
  height: ${px2rem(spacing.size11)};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2rem(spacing.size03)} ${px2rem(spacing.size04)};
  cursor: pointer;
  background-color: ${colorSpecific.background2};
  color: ${colorSpecific.content1};
  ${typography.button};
  text-decoration: none;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${(props) =>
      props.disabled ? colors.gray10 : colors.gray70};
  }
`;

export const ButtonStyledPrimary = styled(NavLink)<ButtonProps>`
  height: ${px2rem(spacing.size11)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2rem(spacing.size03)} ${px2rem(spacing.size04)};
  cursor: pointer;
  background-color: ${colorSpecific.backgroundInverse};
  color: ${colorSpecific.contentInverse};
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.disabled ? colors.gray10 : colors.gray70};
  }
`;

export const ButtonStyledDanger = styled(NavLink)<ButtonProps>`
  height: ${px2rem(spacing.size11)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2rem(spacing.size03)} ${px2rem(spacing.size04)};
  cursor: pointer;
  background-color: ${colorSpecific.backgroundInverse};
  color: ${colorSpecific.contentInverse};
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.disabled ? colors.gray10 : colors.gray70};
  }
`;
