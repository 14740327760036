import { Menu } from "@headlessui/react";
import styled from "styled-components";
import { colorSpecific } from "../../styles/colors_specific";
import { shadows } from "../../styles/shadows";
import { spacing } from "../../styles/spacing";
import { typography } from "../../styles/typography";
import { px2rem } from "../../styles/utils";

const Wrapper = styled.div`
  position: relative;
  z-index: 100;
`;

const MenuButton = styled(Menu.Button)`
  padding: 0px;
  outline: none;
  cursor: pointer;
`;

const MenuItems = styled(Menu.Items)`
  position: absolute;
  z-index: 100;
  right: 0;
  top: ${px2rem(spacing.size13)};
  background-color: ${colorSpecific.background1};
  width: 240px;
  ${shadows.l};
  border-radius: ${px2rem(spacing.size01)};

  &:focus {
    outline: none;
  }
`;

const MenuItem = styled(Menu.Item)`
  padding: 0px ${px2rem(spacing.size04)};
  ${typography.bodyM};
  cursor: pointer;

  &:first-child {
    border-top-left-radius: ${px2rem(spacing.size01)};
    border-top-right-radius: ${px2rem(spacing.size01)};
  }

  &:last-child {
    border-bottom-left-radius: ${px2rem(spacing.size01)};
    border-bottom-right-radius: ${px2rem(spacing.size01)};
  }

  &:hover {
    background-color: ${colorSpecific.backgroundHover};
  }
`;

const MenuItemTitle = styled.p`
  padding: ${px2rem(spacing.size02)} 0px;
`;

const MenuItemSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colorSpecific.border4};
`;

const DropdownMenuStyled = {
  Wrapper,
  MenuButton,
  MenuItems,
  MenuItem,
  MenuItemTitle,
  MenuItemSeparator,
};

export default DropdownMenuStyled;
