import type { MouseEventHandler, PropsWithChildren } from "react";
import type { CypressProps } from "@/src/ui/view_models/cypress";
import { ActionButtonStyled } from "./action_button.styled";

export interface ActionButtonProps extends CypressProps {
  onClick?: MouseEventHandler;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  className?: string;
  bstyle?: "standard" | "primary" | "danger";
}

export const ActionButton = ({ className, children, onClick, type, disabled, bstyle = "standard", "data-cy": dataCy }: PropsWithChildren<ActionButtonProps>) => {
  return (
    <ActionButtonStyled className={className} type={type} bstyle={bstyle} onClick={onClick} disabled={disabled} data-cy={dataCy}>
      {children}
    </ActionButtonStyled>
  );
};
