import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import type { MiscSliceState } from "../view_models/misc.slice";
import i18next from "i18next";
import type { ConfigFormData } from "../pages/misc/view_models/config";
import { LocalStorageKey } from "../view_models/local_storage";
import { setLoader } from "./ui.slice";

const initialStateFactory = (): MiscSliceState => ({});

export const saveConfigThunk = createAsyncThunk<
  void,
  ConfigFormData,
  { state: RootState }
>("misc/saveConfig", async (data, { dispatch }) => {
  try {
    dispatch(setLoader(true));

    i18next.changeLanguage(data.language);
    localStorage.setItem(LocalStorageKey.Language, data.language);
  } finally {
    dispatch(setLoader(false));
  }
});

const miscSlice = createSlice({
  name: "misc.slice",
  initialState: initialStateFactory(),
  reducers: {},
});

export default miscSlice.reducer;
