import type { FlattenSimpleInterpolation } from "styled-components";
import { css } from "styled-components";

export type shadowsType = "l";

export const shadows: Record<shadowsType, FlattenSimpleInterpolation> = {
  l: css`
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  `,
};
