import { lazy } from "react";
import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { BaseLayout } from "@/src/ui/components/base_layout/base_layout";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import Page404 from "@/src/ui/components/error_pages/404";
import { SuspenseMainLoader } from "@/src/ui/components/suspense_main_loader/suspense_main_loader";
import { RouteMiddleware } from "./route_middleware";
import { useAuthMiddleware } from "./middlewares/auth_middleware.hook";

const LoginPage = lazy(
  () => import("@/src/ui/pages/auth/components/login_page/login_page")
);
const HomePage = lazy(
  () => import("@/src/ui/pages/misc/components/home_page/home_page")
);
const ResourcesPage = lazy(
  () => import("@/src/ui/pages/misc/components/resources_page/resources_page")
);
const ConfigPage = lazy(
  () => import("@/src/ui/pages/misc/components/config_page/config_page")
);
const PrivacyPolicyPage = lazy(
  () =>
    import(
      "@/src/ui/pages/misc/components/privacy_policy_page/privacy_policy_page"
    )
);
const LegalNoticePage = lazy(
  () =>
    import("@/src/ui/pages/misc/components/legal_notice_page/legal_notice_page")
);

const PatientsPage = lazy(
  () => import("@/src/ui/pages/patients/components/patients_page/patients_page")
);
const NewPatientPage = lazy(
  () =>
    import(
      "@/src/ui/pages/patients/components/new_patient_page/new_patient_page"
    )
);
const PatientDetailPage = lazy(
  () =>
    import(
      "@/src/ui/pages/patients/components/patient_detail_page/patient_detail_page"
    )
);
const SessionPage = lazy(
  () => import("@/src/ui/pages/sessions/components/session_page/session_page")
);

export const routes: Array<RouteObject> = [
  {
    path: "/",
    element: (
      <AppErrorBoundary>
        <SuspenseMainLoader>
          <Navigate to="/app" replace />
        </SuspenseMainLoader>
      </AppErrorBoundary>
    ),
  },
  {
    path: "/login",
    element: (
      <AppErrorBoundary>
        <SuspenseMainLoader>
          <LoginPage />
        </SuspenseMainLoader>
      </AppErrorBoundary>
    ),
  },
  {
    path: "/app/*",
    element: <BaseLayout fullWidth navTransparent />,
    children: [
      {
        index: true,
        element: (
          <AppErrorBoundary>
            <SuspenseMainLoader>
              <RouteMiddleware validationHook={useAuthMiddleware}>
                <HomePage />
              </RouteMiddleware>
            </SuspenseMainLoader>
          </AppErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "/resources",
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: (
          <AppErrorBoundary>
            <SuspenseMainLoader>
              <RouteMiddleware validationHook={useAuthMiddleware}>
                <ResourcesPage />
              </RouteMiddleware>
            </SuspenseMainLoader>
          </AppErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "/config",
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: (
          <AppErrorBoundary>
            <SuspenseMainLoader>
              <RouteMiddleware validationHook={useAuthMiddleware}>
                <ConfigPage />
              </RouteMiddleware>
            </SuspenseMainLoader>
          </AppErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "/privacy-policy",
    element: (
      <AppErrorBoundary>
        <SuspenseMainLoader>
          <RouteMiddleware validationHook={useAuthMiddleware}>
            <PrivacyPolicyPage />
          </RouteMiddleware>
        </SuspenseMainLoader>
      </AppErrorBoundary>
    ),
  },
  {
    path: "/legal-notice",
    element: (
      <AppErrorBoundary>
        <SuspenseMainLoader>
          <RouteMiddleware validationHook={useAuthMiddleware}>
            <LegalNoticePage />
          </RouteMiddleware>
        </SuspenseMainLoader>
      </AppErrorBoundary>
    ),
  },
  {
    path: "/patients",
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: (
          <AppErrorBoundary>
            <SuspenseMainLoader>
              <RouteMiddleware validationHook={useAuthMiddleware}>
                <PatientsPage />
              </RouteMiddleware>
            </SuspenseMainLoader>
          </AppErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "/patients/new",
    element: (
      <AppErrorBoundary>
        <SuspenseMainLoader>
          <RouteMiddleware validationHook={useAuthMiddleware}>
            <NewPatientPage />
          </RouteMiddleware>
        </SuspenseMainLoader>
      </AppErrorBoundary>
    ),
  },
  {
    path: "/patients/:id",
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: (
          <AppErrorBoundary>
            <SuspenseMainLoader>
              <RouteMiddleware validationHook={useAuthMiddleware}>
                <PatientDetailPage />
              </RouteMiddleware>
            </SuspenseMainLoader>
          </AppErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "/patients/:patientId/sessions/:sessionId",
    element: (
      <AppErrorBoundary>
        <SuspenseMainLoader>
          <RouteMiddleware validationHook={useAuthMiddleware}>
            <SessionPage />
          </RouteMiddleware>
        </SuspenseMainLoader>
      </AppErrorBoundary>
    ),
  },
  { path: "*", element: <Page404 /> },
];
