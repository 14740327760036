export type colorsType =
  // basic
  | "white"
  | "black"
  // Gray
  | "gray10"
  | "gray20"
  | "gray30"
  | "gray40"
  | "gray50"
  | "gray60"
  | "gray70"
  | "gray80"
  | "gray90"
  | "gray100"
  // Blue
  | "blue10"
  | "blue30"
  | "blue60"
  | "blue70"
  | "blue80"
  // Red
  | "red10"
  | "red30"
  | "red50"
  | "red70"
  | "red90"
  // Orange
  | "orange10"
  | "orange30"
  | "orange60"
  | "orange70"
  | "orange90"
  // Green
  | "green10"
  | "green30"
  | "green50"
  | "green70"
  | "green90"
  // Yellow
  | "yellow10"
  | "yellow30"
  | "yellow60"
  | "yellow70"
  | "yellow80"
;

export const colors: Record<colorsType, string> = {
  // basic
  white: "#FFFFFF",
  black: "#000000",

  // grayscale
  gray10: "#F2F2F2",
  gray20: "#E6E6E6",
  gray30: "#D9D9D9",
  gray40: "#BFBFBF",
  gray50: "#999999",
  gray60: "#737373",
  gray70: "#4D4D4D",
  gray80: "#333333",
  gray90: "#262626",
  gray100: "#1A1A1A",

  // Gray
  blue10: "#E7ECFE",
  blue30: "#718EF9",
  blue60: "#0D3FF5",
  blue70: "#0938E1",
  blue80: "#0832C9",

  // Red
  red10: "#FFEAF0",
  red30: "#FFCCD6",
  red50: "#F3989F",
  red70: "#ED737C",
  red90: "#D12F3A",

  // Orange
  orange10: "#FCF2E2",
  orange30: "#F7E0B7",
  orange60: "#F3CC8A",
  orange70: "#EFB760",
  orange90: "#E99A37",

  //Green
  green10: "#DAF0EE",
  green30: "#A7DBD4",
  green50: "#71C6B9",
  green70: "#3CAF9F",
  green90: "#008D79",

  // Yellow
  yellow10: "#FFFBCC",
  yellow30: "#FFF580",
  yellow60: "#FFEB00",
  yellow70: "#FFD500",
  yellow80: "#FFBF00"
};
