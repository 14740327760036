import styled from "styled-components";
import { colorSpecific } from "../../styles/colors_specific";
import { spacing } from "../../styles/spacing";
import { px2rem } from "../../styles/utils";

export const SideMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colorSpecific.backgroundInverse};
  height: 100%;
`;

export const SideMenuLogoStyled = styled.div`
  display: flex;
  padding: ${px2rem(spacing.size04)} ${px2rem(spacing.size06)};
  color: ${colorSpecific.contentInverse};
  cursor: pointer;
`;

export const SideMenuNewStyled = styled.div`
  display: flex;
  padding: 10px ${px2rem(spacing.size06)};
`;

export const SideMenuNavigationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${px2rem(spacing.size04)};
  padding: ${px2rem(spacing.size03)};
`;
