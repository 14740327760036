import { colors } from "./colors";

export type colorsType =
  // Content
  | "content1"
  | "content2"
  | "content3"
  | "contentInverse"
  | "contentHover"
  | "contentDisabled"
  // Background
  | "background1"
  | "background2"
  | "background3"
  | "backgroundInverse"
  | "backgroundHover"
  | "backgroundDisabled"
  //Border
  | "border1"
  | "border2"
  | "border3"
  | "border4"
  | "borderInverse"
  // Semantic
  | "semanticHighlight"
  | "semanticDanger"
  | "semanticDangerLight"
  | "semanticWarning"
  | "semanticWarningLight"
  | "semanticSuccess"
  | "semanticSuccessLight"
  | "semanticProductCosmelan"
  | "semanticInfo"
  | "semanticInfoLight"
  // Semantic State
  | "semanticHighlightHover"
  | "semanticDangerHover"
  | "semanticDangerLightHover"
  | "semanticWarningHover"
  | "semanticWarningLightHover"
  | "semanticSuccessHover"
  | "semanticSuccessLightHover"
  | "semanticInfoHover"
  | "semanticInfoHoverLight"
  // Phototypes
  | "phototypes.1"
  | "phototypes.2"
  | "phototypes.3"
  | "phototypes.4"
  | "phototypes.5"
  | "phototypes.6";

export const colorSpecific: Record<colorsType, string> = {
  content1: colors.black,
  content2: colors.gray70,
  content3: colors.gray60,
  contentInverse: colors.white,
  contentHover: colors.gray90,
  contentDisabled: colors.gray40,

  background1: colors.white,
  background2: colors.gray10,
  background3: colors.gray30,
  backgroundInverse: colors.black,
  backgroundHover: colors.gray20,
  backgroundDisabled: colors.gray10,

  border1: colors.black,
  border2: colors.gray50,
  border3: colors.gray30,
  border4: colors.gray10,
  borderInverse: colors.white,

  semanticHighlight: colors.gray100,
  semanticDanger: colors.red90,
  semanticDangerLight: colors.red10,
  semanticWarning: colors.orange90,
  semanticWarningLight: colors.orange10,
  semanticSuccess: colors.green90,
  semanticSuccessLight: colors.green10,
  semanticInfo: colors.gray50,
  semanticInfoLight: colors.gray10,

  semanticHighlightHover: colors.gray70,
  semanticDangerHover: colors.red70,
  semanticDangerLightHover: colors.red30,
  semanticWarningHover: colors.gray40,
  semanticWarningLightHover: colors.gray30,
  semanticSuccessHover: colors.green70,
  semanticSuccessLightHover: colors.green30,
  semanticInfoHover: colors.gray40,
  semanticInfoHoverLight: colors.gray30,

  semanticProductCosmelan: "#9A7E70",

  "phototypes.1": "#EFCCAD",
  "phototypes.2": "#E2B08C",
  "phototypes.3": "#CE9B7A",
  "phototypes.4": "#B6754E",
  "phototypes.5": "#A25B2A",
  "phototypes.6": "#3B1F1C",
};
