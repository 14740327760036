import type { FlattenSimpleInterpolation } from "styled-components";
import { createGlobalStyle, css } from "styled-components";
import GraphikLight from "@/src/ui/assets/fonts/Graphik-Light-Cy.otf";
import GraphikRegular from "@/src/ui/assets/fonts/Graphik-Regular-Cy.otf";
import GraphikMedium from "@/src/ui/assets/fonts/Graphik-Medium-Cy.otf";
import GraphikBold from "@/src/ui/assets/fonts/Graphik-Bold-Cy.otf";

export const FontStyled = createGlobalStyle`
  /* Graphik */
  @font-face {
    font-family: Graphik;
    src: url(${GraphikRegular}) format("opentype");
    font-display: optional;
    font-weight: 400;
  }
  @font-face {
    font-family: Graphik;
    src: url(${GraphikMedium}) format("opentype");
    font-display: optional;
    font-weight: 500;
  }
  @font-face {
    font-family: Graphik;
    src: url(${GraphikBold}) format("opentype");
    font-display: optional;
    font-weight: 700;
  }
  @font-face {
    font-family: Graphik;
    src: url(${GraphikLight}) format("opentype");
    font-display: optional;
    font-weight: 300;
  }
`;

declare type FontType = "Graphik";

export const fonts: Record<FontType, FlattenSimpleInterpolation> = {
  Graphik: css`
    font-family: Graphik, sans-serif;
  `,
};
