import type { FlattenSimpleInterpolation } from "styled-components";
import { css } from "styled-components";

export type BreakpointTypes = "xs" | "s" | "m" | "l" | "xl";

export const breakpoints: Record<BreakpointTypes, number> = {
  xs: 0,
  s: 767,
  m: 1023,
  l: 1279,
  xl: 1919,
};

export const includeMedia = (
  type: BreakpointTypes,
  styles: FlattenSimpleInterpolation
) => css`
  @media (min-width: ${breakpoints[type]}px) {
    ${styles}
  }
`;
